import React, { useState } from "react";
import { Link } from "gatsby";

import Container from "../Container";

import Logo from "../../resources/assets/logo.svg";
import MagnifyingGlass from "../../resources/assets/magnifying-glass.svg";
import Account from "../../resources/assets/account.svg";
import styles from "./Header.module.css";

const Header = () => {
	const [ headerVisible, setHeaderVisible ] = useState(false);
	return (
		<header className={styles.header}>
			<Container>
				<nav className={styles.navigation}>
					<Link to="/"><Logo className={styles.logo} /></Link>
					<div className={headerVisible ? styles.showHeader : styles.hideHeader}>
						<ul className={styles.mainLinks}>
							<li className={styles.hasSubNav}>
								<Link activeClassName={styles.active} to="/work">Who We Serve</Link>
								<ul>
									<li><Link activeClassName={styles.active} to="/agency-marketing">Agencies</Link></li>
									<li><Link activeClassName={styles.active} to="/multi-location">Multi-location</Link></li>
								</ul>
							</li>
							<li className={styles.hasSubNav}>
								<Link activeClassName={styles.active} to="/solutions">Solutions</Link>
								<ul>
									<li><Link activeClassName={styles.active} to="/solutions">Capabilities</Link></li>
									<li><Link activeClassName={styles.active} to="/the-trade-desk">Self-Service DSP</Link></li>
									<li><Link activeClassName={styles.active} to="/passportone">Passport One</Link></li>
								</ul>
							</li>
							<li className={styles.hasSubNav}>
								<Link activeClassName={styles.active} to="/resources">Insights</Link>
								<ul>
									<li><Link activeClassName={styles.active} to="/blog">Blog</Link></li>
									<li><Link activeClassName={styles.active} to="/case-studies">Case Studies</Link></li>
									<li><Link activeClassName={styles.active} to="/multi-location-marketing-resources">Franchise Hub</Link></li>
									<li><Link activeClassName={styles.active} to="/identity">Identity Hub</Link></li>
									<li><Link activeClassName={styles.active} to="/povs">POV</Link></li>
									<li><Link activeClassName={styles.active} to="/white-papers">Guides</Link></li>	
								</ul>
							</li>
							<li className={styles.hasSubNav}>
								<Link activeClassName={styles.active} to="/about-us">Our Story</Link>
								<ul>
									<li><Link activeClassName={styles.active} to="/about-us">About Us</Link></li>
									<li><Link activeClassName={styles.active} to="/careers">Careers</Link></li>
									<li><Link activeClassName={styles.active} to="/recognition">Press</Link></li>
								</ul>
							</li>
							<li><Link activeClassName={styles.active} to="/contact-us" className={styles.cta}>Contact Us</Link></li>
						</ul>
					</div>
					<ul className={styles.secondaryLinks}>
						<li><Link to="https://portal.goodwaygroup.com/login" target="_blank"><Account /></Link></li>
					</ul>
					<button className={headerVisible ? styles.openHotdog : styles.hotdog} onClick={() => setHeaderVisible(!headerVisible)}>
						<span className={styles.sausage} />
						<span className={styles.bun} />
					</button>
				</nav>
			</Container>
		</header>
	);
};

export default Header;
