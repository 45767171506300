import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Helmet } from "react-helmet";

const SEO = ({ title, seoBody }) => (
    <Helmet>
		{title && <title>{title}</title>}
		{ReactHtmlParser(seoBody)}
	</Helmet>
);

export default SEO;
