import React from "react";
import { Link } from "gatsby";
import Container from "../Container";
import ModuleSpacing from "../ModuleSpacing";

import LogoMark from "../../resources/assets/logo-mark-new.svg";
import Facebook from "../../resources/assets/facebook.svg";
import Instagram from "../../resources/assets/instagram.svg";
import LinkedIn from "../../resources/assets/linkedin.svg";
import Twitter from "../../resources/assets/twitter.svg";
import YouTube from "../../resources/assets/youtube.svg";

import styles from "./Footer.module.css";

const Footer = () => (
	<footer>
		<Container>
			<ModuleSpacing bottom={false}>
				<div className={styles.container}>
				<div className={styles.nav1}>
					<strong>Solutions</strong>
						<ul>
							<li>
								<a href="https://www.goodway-media.com/solutions">Capabilities</a>
							</li>
							<li>
								<a href="https://www.goodway-media.com/the-trade-desk">Self-Service DSP</a>
							</li>
							<li>
								<a href="https://www.goodway-media.com/measurement-services">Measurement</a>
							</li>
							<li>
								<a href="https://www.goodway-media.com/passportone">Passport One</a>
							</li>	
							<li>
								<a href="https://www.goodway-media.com/media-solutions">Media Solutions</a>
							</li>						
						</ul>
					
					<strong>Resources</strong>
						<ul>
							<li>
								<a href="https://www.goodway-media.com/blog">Blog</a>
							</li>
							<li>
								<a href="https://www.goodway-media.com/case-studies">Case Studies</a>
							</li>							
							<li>
								<a href="https://www.goodway-media.com/events">Events</a>
							</li>
							<li>
								<a href="https://www.goodway-media.com/multi-location-marketing-resources">Franchise Hub</a>
							</li>
							<li>
								<a href="https://www.goodway-media.com/data-clean-room-resources">Data Clean Room Hub</a>
							</li>
							<li>
								<a href="https://www.goodway-media.com/identity">Identity Hub</a>
							</li>							
							<li>
								<a href="https://www.goodway-media.com/povs">POV</a>
							</li>
							<li>
								<a href="https://www.goodway-media.com/white-papers">White Papers</a>
							</li>
						</ul>
					
					<strong><a href="https://www.goodway-media.com/contact-us">Contact Us</a></strong>
				</div>
				<div className={styles.nav2}>
					<strong>Who We Are</strong>
						<ul>
							<li>
								<a href="https://www.goodway-media.com/about-us">About Us</a>
							</li>
							<li>
								<a href="https://www.goodway-media.com/careers">Careers</a>
							</li>							
							<li>
								<a href="https://www.goodway-media.com/recognition">Press</a>
							</li>							
						</ul>
					
					<strong>Expertise</strong>
						<ul>
							<li>
								<a href="https://www.goodway-media.com/agency-marketing">Agency Marketing</a>
							</li>
							<li>
								<a href="https://www.goodway-media.com/auto-marketing">Automotive Marketing</a>
							</li>
							<li>
								<a href="https://www.goodway-media.com/franchise-marketing-agency">Franchise Marketing</a>
							</li>
							<li>
								<a href="https://www.goodway-media.com/multi-location">Multi-location Marketing</a>
							</li>
						</ul>
					<strong><a href="https://www.goodwaygroup.com/">Goodway Group</a></strong>
				</div>
					<div className={styles.social}>
						<strong>Social</strong>
						<ul>
							<li>
								<a href="https://www.facebook.com/goodwaygroup/" target="_blank" rel="noreferrer"><Facebook /> Facebook</a>
							</li>
							<li>
								<a href="https://www.instagram.com/goodwaygroup/" target="_blank" rel="noreferrer"><Instagram /> Instagram</a>
							</li>
							<li>
								<a href="https://www.linkedin.com/company/goodway-group" target="_blank" rel="noreferrer"><LinkedIn /> LinkedIn</a>
							</li>
							<li>
								<a href="https://twitter.com/goodwaygroup" target="_blank" rel="noreferrer"><Twitter /> Twitter</a>
							</li>
							<li>
								<a href="https://www.youtube.com/channel/UC3dKyD1ZRtthnhnXdCF8ELQ" target="_blank" rel="noreferrer"><YouTube /> YouTube</a>
							</li>
						</ul>
						<LogoMark />
					</div>
				</div>
				<div className={`${styles.container} ${styles.bottom}`}>
					&copy; {new Date().getFullYear()}, Goodway Group
					<ul>
						<li><Link to="/privacy-policy">Privacy Policy</Link></li>
						<li><Link to="/terms">Terms &amp; Conditions</Link></li>
						<li><Link to="/do-not-sell-info">Do Not Sell My Information</Link></li>
					</ul>
				</div>
			</ModuleSpacing>
		</Container>
	</footer>
);

export default Footer;
